<template>
    <v-container class="staking-container">
        <page-filters-section top-img="/img/staking/staff_header.png">
            <h1>Staking</h1>
            <span>Here you can unstake your tokens from the 1st staking event to claim your free Genesis Wizard NFT.</span>
            <br/><br/>
        </page-filters-section>

        <div v-if="!isWalletConnected" class="page-content">
            <connect-wallet></connect-wallet>
        </div>
        <div v-if="isWalletConnected" class="page-content">
            <v-progress-circular
                v-if="loadingStaking"
                size="300"
                indeterminate
                style="width: 100% !important;"
            ></v-progress-circular>
            <v-row class="stake-chests" v-if="!loadingStaking">
                <v-col cols="12" lg="3" order-lg="2" class="buttons-all">
                    <v-row style="padding: 12px; width: 100%;">
                        <p>You can claim <b>{{ wizardsAmountToClaim }}</b> Wizards.<br/>
                            Unstake remaining stakes to get more Wizards</p>
                    </v-row>
                    <!--                    <v-row>-->
                    <!--                        <v-btn-->
                    <!--                            v-bind:disabled="myStakesAmounts.active === 0 || loadingData"-->
                    <!--                            @click="unStakeAll()"-->
                    <!--                        >-->
                    <!--                            <img src="/img/staking/icon-chest.svg"/>-->
                    <!--                            Unstake all ({{ myStakesAmounts.active }})-->
                    <!--                        </v-btn>-->
                    <!--                    </v-row>-->
                    <v-row>
                        <v-btn
                            class=""
                            v-bind:disabled="wizardsAmountToClaim===0 || loadingData"
                            @click="claimAllWizards()"
                        >
                            <img src="/img/staking/icon-wizard.svg"/>
                            Claim all Wizards ({{ Math.min(wizardsAmountToClaim, maxClaimAmount) }})
                        </v-btn>
                    </v-row>
                    <!-- <v-row class="flex-column mt-10" v-if="isDebugEnv">
                        DEBUG:
                        <v-btn
                            @click="approveToStake()"
                            v-bind:disabled="loadingData"
                        >
                            1. Approve ✔
                        </v-btn>
                        <v-btn
                            @click="newStake()"
                            v-bind:disabled="loadingData"
                        >
                            2. New stake
                        </v-btn>
                        <v-btn
                            @click="fetchMyStakes()"
                            v-bind:disabled="loadingData"
                        >
                            Force refresh stakes
                        </v-btn>
                    </v-row> -->
                </v-col>
                <v-col cols="12" lg="9" order-lg="1" class="sm-align-center sm-justify-center">
                    <v-row>
                        <h2>Existing stakes</h2>
                    </v-row>
                    <v-row>
                        <v-col v-if="0 === myStakes.length">
                            <h3 class="text-center">- No stakes -</h3>
                        </v-col>
                        <v-col cols="2" v-for="(stakeObj,index) in myStakes" :key="stakeObj.id" class="single-stake">
                            <stake-unactive-chest
                                class="chest-unactive"
                                v-if="isStatusUnactive(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                                v-bind:stake-period-second="stakePeriodSecond"
                            ></stake-unactive-chest>

                            <stake-active-chest
                                class="chest-active"
                                v-if="isStatusActive(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                                v-bind:chest-loading="activeChestLoading"
                                @unstake-event="unstakeHandler"
                            ></stake-active-chest>

                            <stake-claim-wizard-chest
                                class="chest-to-claim-wizard"
                                v-if="isStatusOpenedAndWizardToClaim(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                                v-bind:chest-loading="claimWizardChestLoading"
                                @claim-wizard-event="claimWizardHandler"
                            ></stake-claim-wizard-chest>

                            <stake-claimed-chest
                                class="chest-opened"
                                v-if="isStatusOpened(stakeObj)"
                                v-bind:stake-obj="stakeObj"
                                v-bind:loading-data="loadingData"
                            ></stake-claimed-chest>
                            <!--                            Id: {{ stakeObj.id }}<br/>-->
                            <!--                            Index: {{ index }}<br/>-->
                            <!--                            Unstaked: {{ stakeObj.unstaked }}<br/>-->
                            <!--                            wizardToClaim: {{ stakeObj.wizardToClaim }}<br/>-->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <dialog-box
            title="Wizard claimed"
            width="500"
            v-bind:model="claimedWizardDialog"
            v-on:dialogClosed="claimedWizardDialog=false"
        >
            <v-card>
                <v-card-text class="mdi-format-align-middle text-center">
                    <img src="/img/wizard-shadow_250.png" style="height:150px"/><br/>
                    <p>
                        Your Wizard was created.<br/>
                        It will appear soon on the "My Wizards" tab
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn :to="{ name: 'MyWizards', params: {}}">Go to My Wizards</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="claimedWizardDialog=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </dialog-box>

    </v-container>
</template>

<script>
import StakeUnactiveChest from "../components/stake/stake-unactive-chest";
import StakeActiveChest from "../components/stake/stake-active-chest";
import StakeClaimWizardChest from "../components/stake/stake-claim-wizard-chest";
import StakeClaimedChest from "../components/stake/stake-claimed-chest";
import DialogBox from "../components/dialog-box";
import PageFiltersSection from "../components/page-filters-section";
import {stakePeriodSecond, StakingContract, StakingWizardMinterContract} from "../services/contracts/stakingContract";
import ConnectWallet from "@/views/ConnectWallet";

export default {
    components: {
        ConnectWallet,
        PageFiltersSection,
        DialogBox, StakeClaimedChest, StakeClaimWizardChest, StakeActiveChest, StakeUnactiveChest
    },
    data() {
        return {
            stakingContract: null,
            stakingMinterContract: null,
            maxClaimAmount: 25,
            stakePeriodSecond: stakePeriodSecond,
            userClaimedAmount: 0,
            myStakesAmounts: {locked: 0, active: 0, unstaked: 0},
            myStakes: [],
            claimedWizardDialog: false,
            loadingData: true,
            loadingStaking: true,
            activeChestLoading: {},
            claimWizardChestLoading: {},
        }
    },
    mounted: function () {
        if (this.isWalletConnected) {
            this.fetchMyStakes();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.loadingStaking = false;
                this.fetchMyStakes();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        wizardsAmountToClaim() {
            return this.myStakesAmounts.unstaked - this.userClaimedAmount;
        },
        isDebugEnv() {
            return 'development' === process.env.NODE_ENV;
        }
    },
    methods: {
        calculateEndStakingDurationMs(startTimestamp) {
            startTimestamp = parseInt(startTimestamp);
            return ((startTimestamp + this.stakePeriodSecond) - Math.floor(Date.now() / 1000)) * 1000;
        },
        approveToStake() {
            this.loadingData = true;
            this.stakingContract.approveToStake().then((res) => {
                // console.log(res);
            }).finally(() => {
                this.loadingData = false;
            });
        },
        newStake() {
            this.loadingData = true;
            this.stakingContract.newStake().then((res) => {
                this.fetchMyStakes();
            }).finally(() => {
                this.loadingData = false;
            });
        },
        unstakeHandler(id) {
            this.unStake(id);
        },
        unStake(id) {
            this.loadingData = true;
            this.setActiveChestLoadingValue(id, true);
            this.stakingContract.unStake(id).then((res) => {
                this.fetchMyStakes();
            }).finally(() => {
                this.loadingData = false;
                this.setActiveChestLoadingValue(id, false);
            });
        },

        setActiveChestLoadingValue(id, val) {
            let tmp = JSON.parse(JSON.stringify(this.activeChestLoading));
            tmp[id] = val;
            this.activeChestLoading = tmp;
        },
        claimWizardHandler(id) {
            this.loadingData = true;
            this.setClaimWizardChestLoadingValue(id, true);
            this.stakingMinterContract.claimSingleWizard(id).then((res) => {
                this.claimedWizardDialog = true;
                this.fetchMyStakes();
            }).finally(() => {
                this.loadingData = false;
                this.setClaimWizardChestLoadingValue(id, false);
            });
        },
        setClaimWizardChestLoadingValue(id, val) {
            let tmp = JSON.parse(JSON.stringify(this.claimWizardChestLoading));
            tmp[id] = val;
            this.claimWizardChestLoading = tmp;
        },
        async fetchMyStakes() {
            this.stakingContract = new StakingContract(this.web3);
            this.stakingMinterContract = new StakingWizardMinterContract(this.web3);

            this.myStakesAmounts = {locked: 0, active: 0, unstaked: 0};
            this.loadingData = true;
            this.userClaimedAmount = parseInt(await this.getUserClaimedAmount());
            this.stakingContract.getMyStakes().then((res) => {
                this.myStakes = [];
                let claimedWizards = this.userClaimedAmount;
                let toClaimWizardsAmount = res.s.filter(stake => stake.unstaked === true).length;
                for (let i = 0; i < res.s.length; i++) {
                    const unstaked = res.s[i].unstaked;
                    const newStake = {
                        id: res.indexes[i],
                        finalAmount: res.s[i].finalAmount,
                        initialAmount: res.s[i].initialAmount,
                        timestamp: res.s[i].timestamp,
                        unstaked: unstaked,
                        wizardToClaim: unstaked ? claimedWizards < toClaimWizardsAmount : false
                    };
                    this.myStakes.push(newStake);
                    if (unstaked) {
                        claimedWizards += 1;
                        this.myStakesAmounts.unstaked += 1;
                    } else {
                        if (this.isStatusUnactive(newStake)) {
                            this.myStakesAmounts.locked += 1;
                        } else {
                            this.myStakesAmounts.active += 1;
                        }
                    }
                }
            }).finally(() => {
                this.loadingData = false;
                this.loadingStaking = false;
            });
        },
        isStatusUnactive(stake) {
            return (stake.unstaked === false && this.calculateEndStakingDurationMs(stake.timestamp) >= 0);
        },
        isStatusActive(stake) {
            return (stake.unstaked === false && this.calculateEndStakingDurationMs(stake.timestamp) < 0);
        },
        isStatusOpenedAndWizardToClaim(stake) {
            return (stake.unstaked === true && stake.wizardToClaim);
        },
        isStatusOpened(stake) {
            return (stake.unstaked === true && !stake.wizardToClaim);
        },

        unStakeAll() {
            this.loadingData = true;
            this.myStakes.filter(stake => stake.unstaked === false).forEach((stake) => {
                this.stakingContract.unStake(stake.id).then((res) => {
                    // console.log(res);
                }).finally(() => {
                    this.loadingData = false;
                });
            })

        },
        async getUserClaimedAmount() {
            this.loadingData = true;
            return this.stakingMinterContract.userClaimedAmount();
        },
        claimAllWizards() {
            this.loadingData = true;
            this.stakingMinterContract.claimAllWizards().then((res) => {
                // console.log(res);
            }).finally(() => {
                this.loadingData = false;
            });
        },
    }
}
</script>

<style scoped>
.stake-chests {
    padding: 0;
    margin: 0;
}

.single-stake::v-deep img {
    width: 100%;
}

.buttons-all button {
    align-self: self-start;
}

.buttons-all img {
    height: 30px;
}

.single-stake .chest-unactive:hover::v-deep img,
.single-stake .chest-opened:hover::v-deep img {
    animation: shake 4s;
    animation-iteration-count: infinite;
    cursor: not-allowed;
}

.single-stake .chest-active::v-deep img,
.single-stake .chest-to-claim-wizard::v-deep img {
    animation: shake 6s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

.single-stake .chest-active:hover::v-deep img,
.single-stake .chest-to-claim-wizard:hover::v-deep img {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@media only screen and (max-width: 959px) {
    .staking-container {
        padding-left: 32px !important;
    }
}
</style>
