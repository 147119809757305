var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "staking-container" },
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/staking/staff_header.png" } },
        [
          _c("h1", [_vm._v("Staking")]),
          _c("span", [
            _vm._v(
              "Here you can unstake your tokens from the 1st staking event to claim your free Genesis Wizard NFT."
            ),
          ]),
          _c("br"),
          _c("br"),
        ]
      ),
      !_vm.isWalletConnected
        ? _c("div", { staticClass: "page-content" }, [_c("connect-wallet")], 1)
        : _vm._e(),
      _vm.isWalletConnected
        ? _c(
            "div",
            { staticClass: "page-content" },
            [
              _vm.loadingStaking
                ? _c("v-progress-circular", {
                    staticStyle: { width: "100% !important" },
                    attrs: { size: "300", indeterminate: "" },
                  })
                : _vm._e(),
              !_vm.loadingStaking
                ? _c(
                    "v-row",
                    { staticClass: "stake-chests" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "buttons-all",
                          attrs: { cols: "12", lg: "3", "order-lg": "2" },
                        },
                        [
                          _c(
                            "v-row",
                            { staticStyle: { padding: "12px", width: "100%" } },
                            [
                              _c("p", [
                                _vm._v("You can claim "),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.wizardsAmountToClaim)),
                                ]),
                                _vm._v(" Wizards."),
                                _c("br"),
                                _vm._v(
                                  " Unstake remaining stakes to get more Wizards"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.wizardsAmountToClaim === 0 ||
                                      _vm.loadingData,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.claimAllWizards()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking/icon-wizard.svg",
                                    },
                                  }),
                                  _vm._v(
                                    " Claim all Wizards (" +
                                      _vm._s(
                                        Math.min(
                                          _vm.wizardsAmountToClaim,
                                          _vm.maxClaimAmount
                                        )
                                      ) +
                                      ") "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "sm-align-center sm-justify-center",
                          attrs: { cols: "12", lg: "9", "order-lg": "1" },
                        },
                        [
                          _c("v-row", [_c("h2", [_vm._v("Existing stakes")])]),
                          _c(
                            "v-row",
                            [
                              0 === _vm.myStakes.length
                                ? _c("v-col", [
                                    _c("h3", { staticClass: "text-center" }, [
                                      _vm._v("- No stakes -"),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.myStakes, function (stakeObj, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: stakeObj.id,
                                    staticClass: "single-stake",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _vm.isStatusUnactive(stakeObj)
                                      ? _c("stake-unactive-chest", {
                                          staticClass: "chest-unactive",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                            "stake-period-second":
                                              _vm.stakePeriodSecond,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isStatusActive(stakeObj)
                                      ? _c("stake-active-chest", {
                                          staticClass: "chest-active",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                            "chest-loading":
                                              _vm.activeChestLoading,
                                          },
                                          on: {
                                            "unstake-event": _vm.unstakeHandler,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isStatusOpenedAndWizardToClaim(stakeObj)
                                      ? _c("stake-claim-wizard-chest", {
                                          staticClass: "chest-to-claim-wizard",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                            "chest-loading":
                                              _vm.claimWizardChestLoading,
                                          },
                                          on: {
                                            "claim-wizard-event":
                                              _vm.claimWizardHandler,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isStatusOpened(stakeObj)
                                      ? _c("stake-claimed-chest", {
                                          staticClass: "chest-opened",
                                          attrs: {
                                            "stake-obj": stakeObj,
                                            "loading-data": _vm.loadingData,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "dialog-box",
        {
          attrs: {
            title: "Wizard claimed",
            width: "500",
            model: _vm.claimedWizardDialog,
          },
          on: {
            dialogClosed: function ($event) {
              _vm.claimedWizardDialog = false
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "mdi-format-align-middle text-center" },
                [
                  _c("img", {
                    staticStyle: { height: "150px" },
                    attrs: { src: "/img/wizard-shadow_250.png" },
                  }),
                  _c("br"),
                  _c("p", [
                    _vm._v(" Your Wizard was created."),
                    _c("br"),
                    _vm._v(' It will appear soon on the "My Wizards" tab '),
                  ]),
                ]
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    { attrs: { to: { name: "MyWizards", params: {} } } },
                    [_vm._v("Go to My Wizards")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.claimedWizardDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }